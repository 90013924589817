<template>
  <v-row class="mx-0">
    <!-- Partes temporales -->
    <v-col cols="12">
      <div class="card">
        <div class="card-header card-header-icon card-header-rose">
          <div class="card-icon">
            <i class="material-icons">sticky_note_2</i>
          </div>
          <h3 class="card-title">Folio Temporal</h3>
        </div>
        <div class="card-body">
          <v-row v-for="parte in temporales" v-bind:key="parte.id">
            <v-col>
              <TicketBitacora v-bind:parte="parte" @clicked="onClickTicket" />
            </v-col>
            <v-col>
              <v-btn @click="sincronizar">Sincronizar Turno</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
    <v-col cols="12">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel v-if="!showCarga">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            BITACORA DE CARGA
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                  v-model="parte.folio" dense>
                </v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Parámetros medioambientales en centro previo a la carga</legend>
              <v-row>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-temperature-celsius" placeholder="Temperatura (°C)" suffix="° C"
                    v-model="carga.temperatura_centro" dense type="number">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" placeholder="Oxigeno (mg/L)" suffix="mg/L"
                    v-model="carga.oxigeno_centro" dense type="number">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" placeholder="Saturación (%)" v-model="carga.saturacion_centro"
                    suffix="%" dense type="number">
                  </v-text-field>
                </v-col>
              </v-row>
            </fieldset>

            <fieldset class="px-2 py-2 elevation-3" v-if="carga.es_nueva">
              <legend class="rounded-sm px-2 py-1">Información General</legend>
              <v-row class="mx-0">
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho" label="Guia Despacho"
                    v-model="carga.guia_despacho">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaGuiaFormatted" label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="carga.fecha_despacho" @input="menu1 = false" locale="es-cl"
                      prev-icon="chevron_left" next-icon="chevron_right"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="carga.especie" :items="especies" item-text="nombre" item-value="id" label="Especie"
                    placeholder="Seleccione especie" return-object></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-swim" placeholder="Días de ayuno" label="Días de ayuno"
                    v-model="carga.dias_ayuno" type="text" dense>
                  </v-text-field>
                </v-col>



                <!--PARAMETROS-->
                <v-col cols="4" v-for="p in parameters" :key="`par_${p.id}`">
                  <v-select v-model="p.valor" prepend-icon="mdi-form-select" :items="p.opciones" :label="p.nombre"
                    item-text="nombre" item-value="id" dense></v-select>
                </v-col>
                <!--PARAMETROS-->


                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega1" :label="nbodega1"
                    v-model="carga.sello_babor" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega2" :label="nbodega2"
                    v-model="carga.sello_central" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4" v-if="parte.wellboats_id != 7">
                  <v-text-field prepend-icon="mdi-seal" placeholder="N° sello estribor" label="N° sello estribor"
                    v-model="carga.sello_estribor" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 1" label="N° sello Yoma 1"
                    v-model="carga.sello_yoma1" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 2" label="N° sello Yoma 2"
                    v-model="carga.sello_yoma2" dense>
                  </v-text-field>
                </v-col>
              </v-row>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3" v-if="!carga.es_nueva">
              <legend class="rounded-sm px-2 py-1">Información General</legend>
              <v-row class="mx-0">
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho" label="Guia Despacho"
                    v-model="carga.guia_despacho">
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="fechaGuiaFormatted" label="Fecha guía de despacho"
                        prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="carga.fecha_despacho" @input="menu1 = false" locale="es-cl"
                      prev-icon="chevron_left" next-icon="chevron_right"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="carga.especie" :items="especies" item-text="nombre" item-value="id" label="Especie"
                    placeholder="Seleccione especie" return-object></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-swim" placeholder="Días de ayuno" label="Días de ayuno"
                    v-model="carga.dias_ayuno" type="text" dense>
                  </v-text-field>
                </v-col>



                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-swim" placeholder="Natacion en bodegas" label="Natacion en bodegas"
                    v-model="carga.natacion" counter="20" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-air-conditioner" placeholder="Boqueo en bodegas"
                    label="Boqueo en bodegas" v-model="carga.boqueo" counter="20" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-fish" placeholder="Descamacion" label="Descamacion"
                    v-model="carga.descamacion" counter="20" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-rounded-corner" placeholder="Peces esquinados" label="Peces esquinados"
                    v-model="carga.esquinados" counter="20" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-barcode" placeholder="Ataques de lobos" label="Ataques de lobos"
                    v-model="carga.lobos" counter="20" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-medical-bag" placeholder="Peces con heridas" label="Peces con heridas"
                    v-model="carga.heridas" counter="20" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-gate" placeholder="Mortalidad en cerco" label="Mortalidad en cerco"
                    v-model="carga.mortalidad_cerco" counter="20" dense>
                  </v-text-field>
                </v-col>


                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega1" :label="nbodega1"
                    v-model="carga.sello_babor" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" :placeholder="nbodega2" :label="nbodega2"
                    v-model="carga.sello_central" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4" v-if="parte.wellboats_id != 7">
                  <v-text-field prepend-icon="mdi-seal" placeholder="N° sello estribor" label="N° sello estribor"
                    v-model="carga.sello_estribor" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 1" label="N° sello Yoma 1"
                    v-model="carga.sello_yoma1" dense>
                  </v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field prepend-icon="mdi-seal" placeholder="N° sello Yoma 2" label="N° sello Yoma 2"
                    v-model="carga.sello_yoma2" dense>
                  </v-text-field>
                </v-col>
              </v-row>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Lances</legend>
              <v-btn @click="addLance" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
              <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text" rowspan="2">Número</th>
                      <th class="text-center blue-grey darken-4 white-text" rowspan="2">Hora Inicio</th>
                      <th class="text-center blue-grey darken-4 white-text" colspan="4">Parámetros Ambientales</th>
                      <th class="text-center blue-grey darken-4 white-text" rowspan="2">Número de Peces en lance</th>
                      <th class="text-center blue-grey darken-4 white-text" rowspan="2">Hora Término</th>
                      <th class="text-center blue-grey darken-4 white-text" rowspan="2">Tiempo Carga Efectivo</th>
                      <th class="text-center blue-grey darken-4 white-text" rowspan="2">Acciones</th>
                    </tr>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text">Hora medición</th>
                      <th class="text-center blue-grey darken-4 white-text">Oxigeno (mg/L)</th>
                      <th class="text-center blue-grey darken-4 white-text">Saturacion (%)</th>
                      <th class="text-center blue-grey darken-4 white-text">T (°C)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(lance,idx1) in carga.lances" :key="lance.numero">
                      <td style="border-right: 1px solid black;">{{ lance.numero }}</td>
                      <td style="border-right: 1px solid black;">
                        <v-datetime-picker label="Inicio (Fecha Hora)" v-model="lance.hora_inicio" dateFormat="dd-MM-yyyy"
                          :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                          :timePickerProps="{ format: '24hr' }" @input="cargaEfectiva(lance)">
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-datetime-picker label="Medicion (Fecha Hora)" v-model="lance.hora_medicion"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                          :timePickerProps="{ format: '24hr' }">
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-text-field v-model="lance.oxigeno" suffix="mg/L" type="number"></v-text-field>
                      </td>
                      <td>
                        <v-text-field v-model="lance.saturacion" suffix="%" type="number"></v-text-field>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-text-field v-model="lance.temperatura" suffix="° C" type="number"></v-text-field>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-text-field v-model="lance.peces" type="number"></v-text-field>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-datetime-picker label="Termino (Fecha Hora)" v-model="lance.hora_termino"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                          :timePickerProps="{ format: '24hr' }" @input="cargaEfectiva(lance)">
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td style="border-right: 1px solid black;">
                        <v-text-field v-model="lance.carga_efectiva" type="text"></v-text-field>
                      </td>
                      <td>
                        <a @click="deleteLance(idx1)"><span class="material-icons">delete</span></a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Bodegas</legend>
              <v-btn @click="showDialog" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
              <v-simple-table dense class="mx-2 my-2 elevation-3">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text">N°</th>
                      <th class="text-center blue-grey darken-4 white-text">Bodega</th>
                      <th class="text-center blue-grey darken-4 white-text">Número de Peces</th>
                      <th class="text-center blue-grey darken-4 white-text">Peso Promedio (Kg)</th>
                      <th class="text-center blue-grey darken-4 white-text">Biomasa Estimada (Kg)</th>
                      <th class="text-center blue-grey darken-4 white-text">Densidad de Carga (Kg/m3)</th>
                      <th class="text-center blue-grey darken-4 white-text">N° Lance</th>
                      <th class="text-center blue-grey darken-4 white-text">Jaula</th>
                      <th class="text-center blue-grey darken-4 white-text">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(detalle,idx) in carga.bodegas" :key="detalle.numero">
                      <td class="text-center">{{ detalle.numero }}</td>
                      <td class="text-center" v-if="detalle.bodega.bodega != undefined">{{ detalle.bodega.bodega.nombre }}
                      </td>
                      <td class="text-center" v-if="detalle.bodega.bodega == undefined">{{ detalle.bodega.nombre }}</td>
                      <td class="text-center">
                        <v-text-field class="input-right" v-model="detalle.peces" type="number"
                          @input="recalcular(detalle)"></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field class="input-right" v-model="detalle.peso" type="number"
                          @input="recalcular(detalle)"></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field class="input-right" v-model="detalle.biomasa" type="number" readonly></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field class="input-right" v-model="detalle.densidad" type="number"
                          @input="recalcular(detalle)"></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field class="input-center" v-model="detalle.lances"
                          @input="recalcular(detalle)"></v-text-field>
                      </td>
                      <td class="text-center">
                        <v-text-field class="input-center" v-model="detalle.jaula" type="text"
                          @input="recalcular(detalle)"></v-text-field>
                      </td>
                      <td class="text-center">
                        <a @click="deleteBodega(idx)"><span class="material-icons">delete</span></a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">Total/Prom</td>
                      <td>
                        <v-text-field class="input-right" v-model="totalPeces" type="number" readonly></v-text-field>
                      </td>
                      <td>
                        <v-text-field class="input-right" v-model="totalPeso" type="number" readonly></v-text-field>
                      </td>
                      <td>
                        <v-text-field class="input-right" v-model="totalBiomasa" type="number" readonly></v-text-field>
                      </td>
                      <td>
                        <v-text-field class="input-right" v-model="totalDensidad" type="number" readonly></v-text-field>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </fieldset>
            <v-row>
              <v-col cols="12">
                <v-textarea solo counter="1000" label="Observaciones" v-model="carga.observaciones"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5">
                <v-text-field label="Nombre Jefe de Centro" placeholder="Nombre Jefe de Centro"
                  v-model="carga.jefe_centro" dense>
                </v-text-field>

                <!-- <div ref="sketch" id="sketch" style="border :2px solid #3c3c3c;width:100%;height:150px;">
                  <canvas id="canvas"></canvas>
                </div> -->
                <!-- <firma></firma> -->
              </v-col>
              <v-col cols="2">
              </v-col>
              <v-col cols="5">
                <v-select v-if="tecnicos.length > 0" v-model="carga.tecnico_wellboat" :items="tecnicos" item-text="nombre"
                  item-value="id" label="Técnico Wellboat">
                </v-select>
                <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                  v-model="carga.tecnico_wellboat" dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4" class="text-left">
                <v-btn color="" @click="saveCarga(1)" :disabled="carga.temporal == 0">GUARDAR TEMPORAL</v-btn>
              </v-col>
              <v-col cols="4" class="pt-0">
                <v-datetime-picker label="Termino (Fecha Hora) CAM" v-model="termino_cam" dateFormat="dd-MM-yyyy"
                  :datePickerProps="{
                    locale: 'es-es',
                    prevIcon: 'chevron_left',
                    nextIcon: 'chevron_right',
                  }" :timePickerProps="{ format: '24hr' }" :disabled="false">
                  <template slot="dateIcon">
                    <v-icon>calendar_today</v-icon>
                  </template>
                  <template slot="timeIcon">
                    <v-icon>access_time</v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col cols="4" class="text-right">
                <v-btn color="green" @click="saveCarga(0)">GUARDAR CARGA</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="showTraslado">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            BITACORA DE TRASLADO
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                  v-model="parte.folio" dense :readonly="true" background-color="#ccc">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="traslado.parte.empresa" placeholder="Cliente" label="Cliente"
                  v-model="traslado.parte.empresa.razon_social" dense :readonly="true" background-color="#ccc">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field placeholder="Centro Carga" label="Centro Carga" v-model="traslado.centro_carga" dense
                  :readonly="true" background-color="#ccc">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field placeholder="Centro Descarga" label="Centro Descarga" v-model="traslado.centro_descarga"
                  dense :readonly="true" background-color="#ccc">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field placeholder="Hora Inicio" label="Hora Inicio" v-model="traslado.hora_inicio" dense
                  :readonly="true" background-color="#ccc">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field placeholder="Hora Termino" label="Hora Termino" v-model="traslado.hora_termino" dense
                  :readonly="true" background-color="#ccc">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="mx-0 mt-1">
              <v-col cols="4">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho 1era Carga" label="Guia Despacho 1"
                  v-model="traslado.guia_despacho" @change="checkGuia">
                </v-text-field>
              </v-col>
              <v-col cols="4" v-if="traslado.carga_doble">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Guia Despacho 2da Carga" label="Guia Despacho 2"
                  v-model="traslado.guia_despacho_2" @change="checkGuia">
                </v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Mediciones de parámetros</legend>
              <v-btn @click="addMedicionTraslado" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
              <v-simple-table dense class="mx-0 elevation-3 text-center">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th rowspan="2" class="text-center blue-grey darken-4 white-text">Número</th>
                      <th rowspan="2" class="text-center blue-grey darken-4 white-text">HORA</th>
                      <th colspan="4" class="text-center blue-grey darken-4 white-text" v-for="bodega in bodegas"
                        :key="bodega.id">{{ bodega.bodega.nombre }}</th>
                      <th rowspan="2" class="text-center blue-grey darken-4 white-text">Acciones</th>
                    </tr>
                    <tr>
                      <template v-for="i in bodegas.length">
                        <th class="text-center blue-grey darken-4 white-text" :key="i">O2 (mg/L)</th>
                        <th class="text-center blue-grey darken-4 white-text" :key="i">PH</th>
                        <th class="text-center blue-grey darken-4 white-text" :key="i">UV (ml/cm2)</th>
                        <th class="text-center blue-grey darken-4 white-text" :key="i">T (°C)</th>
                      </template>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(parametro,idx2) in traslado.parametros" :key="parametro.numero">
                      <td>{{ parametro.numero }}</td>
                      <td style="border-right: 1px solid black;width: 180px;">
                        <v-datetime-picker label="Hora Medición" v-model="parametro.hora" dateFormat="dd-MM-yyyy"
                          :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                          :timePickerProps="{ format: '24hr' }">
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <template v-for="bodega in bodegas">
                        <td :key="bodega.id">
                          <v-text-field v-model="parametro['b' + bodega['bodega'].id].oxigeno" type="number" step="0.1"
                            class="input-right"></v-text-field>
                        </td>
                        <td :key="bodega.id">
                          <v-text-field v-model="parametro['b' + bodega['bodega'].id].ph" type="number"
                            class="input-right"></v-text-field>
                        </td>
                        <td :key="bodega.id">
                          <v-text-field v-model="parametro['b' + bodega['bodega'].id].uv" type="number"
                            class="input-right"></v-text-field>
                        </td>
                        <td style="border-right: 1px solid black;" :key="bodega.id">
                          <v-text-field v-model="parametro['b' + bodega['bodega'].id].temperatura" type="number"
                            class="input-right"></v-text-field>
                        </td>
                      </template>
                      <td>
                        <a @click="deleteParametroTraslado(idx2)"><span class="material-icons">delete</span></a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </fieldset>
            <v-row>
              <v-col cols="12">
                <v-textarea solo counter="500" label="Observaciones" v-model="traslado.observaciones"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select v-if="tecnicos.length > 0" v-model="traslado.tecnico_wellboat" :items="tecnicos"
                  item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                </v-select>
                <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                  v-model="traslado.tecnico_wellboat" dense>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Supervisor" placeholder="Supervisor" v-model="traslado.supervisor" dense>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select v-if="capitanes.length > 0" v-model="traslado.capitan" :items="capitanes" item-text="nombre"
                  item-value="id" label="Nombre Capitán" dense>
                </v-select>
                <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                  v-model="traslado.capitan" dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn color="" @click="saveTraslado(1)" :disabled="traslado.temporal == 0">GUARDAR TEMPORAL</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="green" @click="saveTraslado(0)">GUARDAR TRASLADO</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="showDescarga">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            BITACORA DE DESCARGA A VIVERO/ACOPIO
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                  v-model="parte.folio" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Guia de despacho" label="Guia de despacho"
                  v-model="descarga.guia_despacho" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Fecha de despacho" label="Fecha de despacho"
                  v-model="descarga.fecha_despacho" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Cliente" label="Cliente" v-model="descarga.cliente"
                  dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Acopio" label="Acopio" v-model="descarga.acopio"
                  dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Recalada" label="Hora Recalada"
                  v-model="descarga.recalada" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Atraco" label="Hora Atraco"
                  v-model="descarga.atraco" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Inicio" label="Hora Inicio"
                  v-model="descarga.hora_inicio" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Hora Termino" label="Hora Termino"
                  v-model="descarga.hora_termino" dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Especie" label="Especie" v-model="descarga.especie"
                  dense :readonly="true" background-color="#ccc"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field dense prepend-icon="mdi-seal" label="Muestreo Repla" placeholder="Muestreo Repla"
                  counter="30" v-model="descarga.muestreo_repla"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense prepend-icon="mdi-seal" label="Entidad Muestradora" placeholder="Entidad Muestradora"
                  counter="30" v-model="descarga.entidad_muestradora"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense prepend-icon="mdi-seal" placeholder="Mortalidad" label="Mortalidad" counter="30"
                  v-model="descarga.mortalidad"></v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Parámetros medioambientales previo a descarga</legend>
              <v-row>
                <v-col cols="4">
                  <v-text-field dense prepend-icon="mdi-seal" label="Saturacion" placeholder="Saturacion" suffix="%"
                    type="number" v-model="descarga.saturacion_centro"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense prepend-icon="mdi-temperature-celsius" label="Temperatura" placeholder="Temperatura"
                    type="number" suffix="° C" v-model="descarga.temperatura_centro"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field dense prepend-icon="mdi-seal" label="Oxigeno" placeholder="Oxigeno" suffix="mg/L"
                    type="number" v-model="descarga.oxigeno_centro"></v-text-field>
                </v-col>
              </v-row>
            </fieldset>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Descargas a Jaulas</legend>
              <v-btn @click="addDescarga" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
              <v-simple-table dense class="mx-2 my-2 elevation-3 text-center">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-center blue-grey darken-4 white-text">N°</th>
                      <th class="text-center blue-grey darken-4 white-text">Bodega</th>
                      <th class="text-center blue-grey darken-4 white-text">Hora Inicio</th>
                      <th class="text-center blue-grey darken-4 white-text">Hora Término</th>
                      <th class="text-center blue-grey darken-4 white-text">N° Jaula Descarga</th>
                      <th class="text-center blue-grey darken-4 white-text">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(descarga,idx3) in descarga.jaulas" :key="descarga.numero">
                      <td>{{ descarga.numero }}</td>
                      <td>
                        <v-select v-model="descarga.bodegas_id" :items="bodegas" item-text="bodega.nombre"
                          item-value="bodega.id" label="Destino">
                        </v-select>
                      </td>
                      <td>
                        <v-datetime-picker label="Inicio (Fecha Hora)" v-model="descarga.hora_inicio"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                          :timePickerProps="{ format: '24hr' }">
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td>
                        <v-datetime-picker label="Termino (Fecha Hora)" v-model="descarga.hora_termino"
                          dateFormat="dd-MM-yyyy"
                          :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                          :timePickerProps="{ format: '24hr' }">
                          <template slot="dateIcon">
                            <v-icon>calendar_today</v-icon>
                          </template>
                          <template slot="timeIcon">
                            <v-icon>access_time</v-icon>
                          </template>
                        </v-datetime-picker>
                      </td>
                      <td><v-text-field v-model="descarga.jaula"></v-text-field></td>
                      <td>
                        <a @click="deleteDescargaJaula(idx3)"><span class="material-icons">delete</span></a>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </fieldset>
            <v-row>
              <v-col cols="12">
                <v-textarea solo counter="500" label="Observaciones" v-model="descarga.observaciones"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-select v-if="capitanes.length > 0" v-model="descarga.capitan" :items="capitanes" item-text="nombre"
                  item-value="id" label="Nombre Capitán" dense>
                </v-select>
                <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                  v-model="descarga.capitan.nombre" dense>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select v-if="tecnicos.length > 0" v-model="descarga.tecnico_wellboat" :items="tecnicos"
                  item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                </v-select>
                <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                  v-model="descarga.tecnico_wellboat" dense>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense prepend-icon="mdi-seal" placeholder="Encargado Acopio" label="Encargado Acopio"
                  v-model="descarga.encargado_acopio" :value="descarga.capitan.nombre"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn color="" @click="saveDescarga(1)" :disabled="descarga.temporal == 0">GUARDAR TEMPORAL</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="green" @click="saveDescarga(0)">GUARDAR DESCARGA</v-btn>
              </v-col>
            </v-row>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="showDescargaPlanta">
          <v-expansion-panel-header color="#263238" style="color: #fff;">
            BITACORA DE DESCARGA DIRECTA A PLANTA
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="mx-0 mt-1">
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Folio Parte" label="Folio Parte"
                  v-model="parte.folio" dense readonly>
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Guia de Despacho" label="Guia de Despacho"
                  v-model="descarga_planta.guia" dense readonly>
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-menu v-model="menu5" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                  offset-y min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="fechaGuiaPlanta" label="Fecha guía de despacho" prepend-icon="mdi-calendar"
                      readonly v-bind="attrs" v-on="on" dense></v-text-field>
                  </template>
                  <v-date-picker v-model="descarga_planta.fecha" @input="menu5 = false" locale="es-cl"
                    prev-icon="chevron_left" next-icon="chevron_right"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-text-field v-if="descarga_planta.parte.empresa" placeholder="Cliente" label="Cliente"
                  v-model="descarga_planta.parte.empresa.razon_social" dense readonly>
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field placeholder="Muestreo" label="Muestreo" v-model="descarga_planta.muestreo" dense>
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field prepend-icon="mdi-barcode" placeholder="Especie" label="Especie"
                  v-model="descarga_planta.especie" dense readonly></v-text-field>
              </v-col>
            </v-row>
            <fieldset class="px-2 py-2 elevation-3">
              <legend class="rounded-sm px-2 py-1">Detalle de Tiempo de Descarga de la Planta</legend>
              <v-tabs center-active v-model="tab">
                <v-tab v-if="parte1.id > 0">Carga Guia: {{ parte1.carga.guia_despacho }}</v-tab>
                <v-tab v-if="parte2.id > 0">Carga Guia: {{ parte2.carga.guia_despacho }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-btn @click="addHoraDescarga(1)" class="my-1" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                  <v-simple-table dense class="mx-0 my-1 elevation-3 text-center">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th rowspan="1" class="text-center blue-grey darken-4 white-text">Hora Inicio</th>
                          <th rowspan="1" class="text-center blue-grey darken-4 white-text">Hora termino</th>
                          <th colspan="1" class="text-center blue-grey darken-4 white-text">Motivo</th>
                          <th colspan="1" class="text-center blue-grey darken-4 white-text">?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="hora in horas1" :key="hora.numero">
                          <td style="border-right: 1px solid black;width: 180px;">
                            <v-datetime-picker label="Hora Inicio" v-model="hora.hora_inicio" dateFormat="dd-MM-yyyy"
                              :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                              :timePickerProps="{ format: '24hr' }">
                              <template slot="dateIcon">
                                <v-icon>calendar_today</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>access_time</v-icon>
                              </template>
                            </v-datetime-picker>
                          </td>
                          <td style="border-right: 1px solid black;width: 180px;">
                            <v-datetime-picker label="Hora Término" v-model="hora.hora_termino" dateFormat="dd-MM-yyyy"
                              :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                              :timePickerProps="{ format: '24hr' }">
                              <template slot="dateIcon">
                                <v-icon>calendar_today</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>access_time</v-icon>
                              </template>
                            </v-datetime-picker>
                          </td>
                          <td>
                            <v-text-field v-model="hora.motivo"></v-text-field>
                          </td>
                          <td>
                            <a @click="deleteHora(hora, 1)"><span class="material-icons">delete</span></a>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
                <v-tab-item>
                  <v-btn @click="addHoraDescarga(2)" class="my-1" color="green"><v-icon>exposure_plus_1</v-icon></v-btn>
                  <v-simple-table dense class="mx-0 my-1 elevation-3 text-center">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th rowspan="1" class="text-center blue-grey darken-4 white-text">Hora Inicio</th>
                          <th rowspan="1" class="text-center blue-grey darken-4 white-text">Hora termino</th>
                          <th colspan="1" class="text-center blue-grey darken-4 white-text">Motivo</th>
                          <th colspan="1" class="text-center blue-grey darken-4 white-text">?</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="hora in horas2" :key="hora.numero">
                          <td style="border-right: 1px solid black;width: 180px;">
                            <v-datetime-picker label="Hora Inicio" v-model="hora.hora_inicio" dateFormat="dd-MM-yyyy"
                              :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                              :timePickerProps="{ format: '24hr' }">
                              <template slot="dateIcon">
                                <v-icon>calendar_today</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>access_time</v-icon>
                              </template>
                            </v-datetime-picker>
                          </td>
                          <td style="border-right: 1px solid black;width: 180px;">
                            <v-datetime-picker label="Hora Término" v-model="hora.hora_termino" dateFormat="dd-MM-yyyy"
                              :datePickerProps="{ locale: 'es-es', prevIcon: 'chevron_left', nextIcon: 'chevron_right' }"
                              :timePickerProps="{ format: '24hr' }">
                              <template slot="dateIcon">
                                <v-icon>calendar_today</v-icon>
                              </template>
                              <template slot="timeIcon">
                                <v-icon>access_time</v-icon>
                              </template>
                            </v-datetime-picker>
                          </td>
                          <td>
                            <v-text-field v-model="hora.motivo"></v-text-field>
                          </td>
                          <td>
                            <a @click="deleteHora(hora, 2)"><span class="material-icons">delete</span></a>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs-items>
            </fieldset>
            <v-row class="mt-6">
              <v-col cols="4">
                <v-select v-if="tecnicos.length > 0" v-model="descarga_planta.tecnico_wellboat" :items="tecnicos"
                  item-text="nombre" item-value="id" label="Técnico Wellboat" dense>
                </v-select>
                <v-text-field v-if="tecnicos.length == 0" label="Técnico Wellboat" placeholder="Técnico Wellboat"
                  v-model="descarga_planta.tecnico_wellboat" dense>
                </v-text-field>
              </v-col>

              <v-col cols="4">
                <v-select v-if="capitanes.length > 0" v-model="descarga_planta.capitan" :items="capitanes"
                  item-text="nombre" item-value="id" label="Nombre Capitán" dense>
                </v-select>
                <v-text-field v-if="capitanes.length == 0" label="Nombre Capitán" placeholder="Nombre Capitán"
                  v-model="descarga_planta.capitan" dense>
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field label="Encargado" placeholder="Encargado" v-model="descarga_planta.encargado" dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea solo counter="500" label="Observaciones" v-model="descarga_planta.observaciones"></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn color="" @click="saveDescargaPlanta(1)" :disabled="descarga_planta.temporal == 0">GUARDAR
                  TEMPORAL</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn color="green" @click="saveDescargaPlanta(0)">GUARDAR DESCARGA</v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
      <v-row v-if="showTraslado">
        <v-col cols="6">
          <v-file-input v-model="files" label="Adjuntar documentación" show-size counter multiple
            prepend-icon="attach_file" chips accept=".pdf"></v-file-input>
        </v-col>
        <v-col cols="12">
          <v-btn color="green" @click="saveFinal" :disabled="finalizar == 1">FINALIZAR SERVICIO WELLBOAT</v-btn>
        </v-col>
      </v-row>
    </v-col>

    <!-- dialogo bodegas -->
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
      <v-card>
        <v-toolbar color="primary" dark>Agregar a bodega</v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select v-model="bodega.bodega" :items="bodegas" item-text="bodega.nombre" item-value="bodega.id"
                label="Bodega" placeholder="Seleccione bodega" return-object></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Peces" placeholder="Peces" @change="calcularBiomasa"
                v-model="bodega.peces"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Peso" placeholder="Peso" @change="calcularBiomasa"
                v-model="bodega.peso"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Biomasa" placeholder="Biomasa" v-model="bodega.biomasa"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Densidad" placeholder="Densidad" v-model="bodega.densidad"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Lance" placeholder="Lance" v-model="bodega.lances"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field label="Jaula" placeholder="Jaula" v-model="bodega.jaula"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="addCargaBodega" color="green">Agregar</v-btn>
          <v-btn @click="dialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- notificacion -->
    <v-snackbar v-model="snackbar" :timeout="5000" :color="color">
      {{ validation_message }}
      <ul v-if="errors.length > 0">
        <li v-for="error in errors" :key="error">{{error}}</li>
      </ul>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

  </v-row>
</template>
<style scoped>
legend {
  background-color: #263238;
  color: #fff;
  font-size: 14pt;
}

.card {
  margin-top: 0;
}

.card .card-header-rose .card-icon {
  background: #263238;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(32, 30, 31, 0.822);
}

fieldset {
  margin-bottom: 10px;
}

.input-right>>>input {
  text-align: right;
}

.input-center>>>input {
  text-align: center;
}

.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: #fff;
}

.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
  color: #fff;
}
</style>
<script>
import { mapState, mapMutations } from 'vuex'
import TicketBitacora from '@/components/TicketBitacora.vue'

export default {
  data: () => ({
    tab: 0,
    showTraslado: false,
    showDescarga: false,
    showDescargaPlanta: false,
    capitanes: [],
    tecnicos: [],
    files: [],
    menuInicio: false,
    menuMedicion: false,
    menuTermino: false,
    dialog: false,
    time: null,
    color: 'green',
    snackbar: false,
    errors: [],
    validation_message: '',
    success: null,
    search: null,
    folioSearch: '',
    empresas: [],
    partes: [],
    cargaIndex: -1,
    isLoading: false,
    wellboat: '',
    parte: {},
    folios: [],
    panel: 0,
    temporales: [],
    bodegas: [],
    total: {},
    showPicker: null,
    menu1: false,
    menu5: false,
    especies: [],
    bodega: {
      bodega: {},
      peces: 0,
      peso: 0,
      biomasa: 0,
      lances: '',
      jaula: ''
    },
    carga: {
      id: 0,
      parte: {},
      especie: {},
      guia_despacho: '',
      fecha_despacho: '',
      dias_ayuno: '0',
      natacion: '',
      boqueo: '',
      descamacion: '',
      esquinados: '',
      lobos: '',
      heridas: '',
      mortalidad_cerco: '',
      sello_babor: '0',
      sello_central: '0',
      sello_estribor: '0',
      temperatura_centro: '',
      oxigeno_centro: '',
      saturacion_centro: '',
      observaciones: '',
      jefe_centro: '',
      tecnico_wellboat: '',
      bodegas: [],
      lances: [],
      es_nueva: true
    },
    traslado: {
      parte: {},
      observaciones: '',
      tecnico_wellboat: '',
      supervisor: '',
      capitan: '',
      parametros: []
    },
    descarga_planta: {
      parte: {},
      observaciones: '',
      tecnico_wellboat: '',
      supervisor: '',
      capitan: '',
      parametros: []
    },
    descarga: {
      partes: {},
      muestreo_repla: '',
      entidad_muestradora: '',
      mortalidad: '',
      saturacion_centro: '',
      temperatura_centro: '',
      oxigeno_centro: '',
      observaciones: '',
      capitan: {
        nombre: '',
      },
      tecnico: {},
      jaulas: [],
      tecnico_wellboat: '',
    },
    horas1: [],
    horas2: [],
    parte1: {},
    parte2: {},
    nbodega1: 'N° sello babor',
    nbodega2: 'N° sello central',
    nbodega3: 'N° sello estribor',
    mouse: { x: 0, y: 0 },
    last_mouse: { x: 0, y: 0 },
    canvas: null,
    ctx: null,
    sketch: null,
    sketch_style: null,
    parameters: [],
    termino_cam: null
  }),
  async mounted() {
    this.empresas = this.$store.getters['empresa/getActive'];
    this.especies = this.$store.getters['especie/getActive'];
    this.bodegas = this.$store.state.user.wellboat.bodegas.map(b => {
      return {
        bodega: b.bodega,
        m3: b.m3,
      }
    });
    await this.loadParameters();

    await this.loadPartesTemporales();

    //firma jefe centro carga
    // this.canvas = document.querySelector("#canvas")
    // this.ctx = this.canvas.getContext("2d")


    // this.sketch = document.querySelector('#sketch');
    // this.sketch_style = getComputedStyle(this.sketch);
    // this.canvas.width = parseInt(this.sketch_style.getPropertyValue('width'));
    // this.canvas.height = parseInt(this.sketch_style.getPropertyValue('height'));

    // this.ctx.lineWidth = 5;
    // this.ctx.lineJoin = 'round';
    // this.ctx.lineCap = 'round';
    // this.ctx.strokeStyle = 'blue';

    // // this.ctx.beginPath();
    // // this.ctx.moveTo(50, 75);
    // // this.ctx.lineTo(150, 150);
    // // this.ctx.stroke();

    // // let that = this

    // this.canvas.addEventListener("mousemove", (e) => {
    //   // console.log(e)
    //   this.last_mouse.x = this.mouse.x;
    //   this.last_mouse.y = this.mouse.y;
    //   this.mouse.x = e.pageX - e.srcElement.offsetLeft;
    //   this.mouse.y = e.pageY - e.srcElement.offsetTop;
    // }, false)

    // this.canvas.addEventListener('mousedown', () => {
    //   // console.log('mousedown',e)
    //   this.canvas.addEventListener('mousemove', this.onPaint, false);
    // }, false);


    // this.canvas.addEventListener('mouseup', () => {
    //   // console.log('mouseup',e)
    //   this.canvas.removeEventListener('mousemove', this.onPaint, false);
    // }, false);
    //firma jefe centro carga


  },
  methods: {
    async loadParameters() {
      let url = `${this.base_url}tipos_bitacoras/${1}/cargar_parametros`
      await this.axios.get(url, this.headers_json).then(r => {
        this.parameters = r.data
      })
    },
    onPaint() {

      console.log('onpaint', 'lx:' + this.last_mouse.x, 'ly:' + this.last_mouse.y, 'x:' + this.mouse.x, 'y:' + this.mouse.y)
      // console.log('ctx',this.ctx)
      this.ctx.beginPath();
      this.ctx.moveTo(this.last_mouse.x, this.last_mouse.y);
      this.ctx.lineTo(this.mouse.x, this.mouse.y);
      // this.ctx.closePath();
      this.ctx.stroke();
    },
    onClickTicket(parte) {
      this.parte = parte;
      this.loadCargaParte();
    },
    showDialog() {
      this.dialog = true;
    },
    async sincronizar() {
      let parte = this.temporales[0];
      if (parte != undefined) {
        let inicio = parte.zarpe.substring(0, 10);
        let termino = parte.recalada.substring(0, 10);
        //console.log(inicio, termino)
        let url = `${this.base_url}turnos/obtener`;
        await this.axios.post(url, { fecha: inicio }, this.headers_json).then((response) => {
          console.log(response);
          if (inicio != termino) {
            this.axios.post(url, { fecha: termino }, this.headers_json).then((response) => {
              console.log(response);
              this.onClickTicket(parte);
            });
          } else {
            this.onClickTicket(parte);
          }
        })
      }
    },
    async loadTecnicos() {
      let url = `${this.base_url}cargas/tecnicos/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.tecnicos = response.data;
        console.log('tecnicos', this.tecnicos)
        this.carga.tecnico_wellboat = this.tecnicos[0];
        this.traslado.tecnico_wellboat = this.tecnicos[0];
        this.descarga.tecnico_wellboat = this.tecnicos[0];
        this.loadCapitanes();
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadCapitanes() {
      let url = `${this.base_url}cargas/capitanes/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.capitanes = response.data
        if (this.capitanes.length) {
          this.carga.capitan = this.capitanes[0]
          this.traslado.capitan = this.capitanes[0]
          this.descarga.capitan = this.capitanes[0]
        }
        if (this.tecnicos.length == 0) {
          this.tecnicos = this.capitanes
          if (this.tecnicos.length > 0) {
            //console.log('load tecnicos', this.tecnicos);
            if (!this.carga.tecnico_wellboat) this.carga.tecnico_wellboat = this.tecnicos[0]
            if (!this.traslado.tecnico_wellboat) this.traslado.tecnico_wellboat = this.tecnicos[0]
            if (!this.descarga.tecnico_wellboat) this.descarga.tecnico_wellboat = this.tecnicos[0]
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadEmpresas() {
      let url = `${this.base_url}empresas`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.empresas = response.data;
        this.carga.empresa = this.empresas[0];
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadBodegas() {
      let url = `${this.base_url}wellboat_bodegas/bywellboat/${this.user.wellboat.id}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.bodegas = response.data;
        this.carga.bodega = this.bodegas[0];
        this.bodega.bodega = this.bodegas[0];
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadEspecies() {
      let url = `${this.base_url}especies`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.especies = response.data;
        this.carga.especie = this.especies[0];
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadCargaParte() {
      let url = `${this.base_url}cargas/byparte/${this.parte.id}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.carga = response.data;
        if (this.carga.especie == undefined || this.carga.especie == null) {
          this.carga.especie = this.especies[0];
        }
        let n = 1;
        this.carga.bodegas.forEach(b => {
          b.numero = n++;
        });
        this.carga.lances.forEach(l => {
          l.hora_inicio = this.moment(l.hora_inicio).toDate();
          l.hora_termino = this.moment(l.hora_termino).toDate();
          l.hora_medicion = this.moment(l.hora_medicion).toDate();
          this.cargaEfectiva(l);
        });

        if (this.carga.parte.termino_cam) {
          this.termino_cam = this.moment(this.carga.parte.termino_cam).toDate()
        }
        /*
        console.log('duplicar: ', this.carga.parte.duplicar);
        console.log('tipopartes_id: ', this.carga.parte.tipopartes_id);
        console.log('carga.temporal: ', this.carga.temporal);
        console.log('parte.temporal: ', this.carga.parte.temporal);
        console.log('parte.id: ', this.carga.parte.id);
        */
        if (
          (this.carga.parte.duplicar == 1 && this.carga.parte.temporal == 0) || (this.carga.parte.duplicar == 0)
          && this.carga.parte.tipopartes_id != 5
          && this.carga.temporal == 0
          && this.carga.parte.temporal == 0
        ) {
          this.panel = 1;
          this.showTraslado = true;
          this.showDescarga = true;
          this.loadTrasladoParte();
          this.loadDescargaParte();
        }

        this.setearParametros();
      }).catch((error) => {
        this.showTraslado = true;
        this.showDescarga = true;
        this.loadTrasladoParte();
        this.loadDescargaParte();
        console.log(error);
      });
    },

    setearParametros() {
      this.parameters.forEach((p, idx) => {
        let par = this.carga.parametros.find(item => item.parametros_id == p.id)
        if (par) {
          this.$set(this.parameters[idx], 'valor', par.parametros_opciones_id)
        }

      });
    },
    async loadTrasladoParte() {
      let url = `${this.base_url}traslados/byparte/${this.parte.id}/${this.user.wellboat.id}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.traslado = response.data;
        //console.log(this.traslado);
        this.traslado.parametros.forEach(p => {
          p.hora = this.moment(p.hora).toDate();
        });
        if (this.traslado.temporal == 0) {
          this.panel = 2;
        }
        if (this.capitanes.length > 0) {
          this.traslado.capitan = this.capitanes.find(c => {
            return c.nombre.trim() == this.traslado.capitan.trim();
          });
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadDescargaParte() {
      let url = `${this.base_url}descargas/byparte/${this.parte.id}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.descarga = response.data;
        this.descarga.jaulas.forEach(l => {
          l.hora_inicio = this.moment(l.hora_inicio).toDate();
          l.hora_termino = this.moment(l.hora_termino).toDate();
        });
        if (this.descarga.temporal == 1) {
          this.panel = 2;
        }
        if (this.descarga.parte.tipo_destinos_id == 4) {
          this.showDescargaPlanta = true;
          this.showDescarga = false;
          this.panel = 3;
          this.loadDescargaPlanta();
        }
        else {
          this.showDescargaPlanta = false;
          this.showDescarga = true;
        }
        //console.log('tecnico', this.descarga.tecnico_wellboat)
        if (this.tecnicos.length > 0) {
          this.descarga.tecnico_wellboat = {
            id: this.descarga.tecnico_wellboat,
            nombre: this.descarga.tecnico_wellboat
          }
        }
        if (this.capitanes.length > 0) {
          this.descarga.capitan = this.capitanes.find(c => {
            if (typeof this.descarga.capitan == 'object') {
              return c.nombre.trim() == this.descarga.capitan.nombre;
            }
            return c.nombre.trim() == this.descarga.capitan.trim();
          });
        }
        if (this.descarga.capitan == undefined) this.descarga.capitan = {};
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadDescargaPlanta() {
      let url = `${this.base_url}descarga_plantas/byparte/${this.parte.id}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        this.panel = 3;
        this.descarga_planta = response.data;
        //console.log(this.descarga_planta);
        this.descarga_planta.horas.forEach(p => {
          p.hora_inicio = this.moment(p.hora_inicio).toDate();
          p.hora_termino = this.moment(p.hora_termino).toDate();
        });
        if (this.capitanes.length > 0) {
          this.descarga_planta.capitan = this.capitanes.find(c => {
            return c.nombre.trim() == this.descarga_planta.capitan.trim();
          });
        }
        this.loadAllPartes();
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadAllPartes() {
      let url = `${this.base_url}partes/porfolio/${this.user.wellboat.id}/${this.parte.folio}`;
      await this.axios.get(url, this.headers_json).then((response) => {
        let partes = response.data;
        if (partes.length == 2) {
          this.parte1 = partes[0];
          this.horas1 = this.descarga_planta.horas.filter(dh => dh.partes_id == this.parte1.id);
          this.parte2 = partes[1];
          this.horas2 = this.descarga_planta.horas.filter(dh => dh.partes_id == this.parte2.id);
        }
        if (partes.length == 1) {
          this.parte1 = partes[0];
          this.horas1 = this.descarga_planta.horas;
        }
      });
    },
    async loadPartesTemporales() {
      let url = `${this.base_url}partes/portipo/temporal`;
      if (this.user.wellboat != undefined && this.user.wellboat.id > 0) {
        url = `${this.base_url}partes/temporales/wellboat/cosecha/${this.user.wellboat.id}`;
      }
      await this.axios.get(url, this.headers_json).then((response) => {
        this.temporales = response.data;
        if (this.temporales.length > 0 && this.user.wellboat != undefined && this.user.wellboat.id > 0) {
          let parte = this.temporales[0];
          if (parte) {
            this.parte = parte;
            this.loadTecnicos();
            this.parte = parte;
            this.loadCargaParte();
            if (this.parte.wellboats_id == 7) {
              // don mauro
              this.nbodega1 = 'N° sello Proa';
              this.nbodega2 = 'N° sello Popa';
            }
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    buscar_valor_parametro(id) {
      let valor = this.parameters.find(p => p.id == id).valor
      return valor
    },
    async saveCarga(temporal = 1) {
      // validation
      this.errors = [];
      let lances = [];
      let bodegas = [];
      if (!this.parte.folio) {
        this.errors.push("Debe elegir un parte de viaje");
      }
      if (this.carga.temperatura_centro == "" || isNaN(this.carga.temperatura_centro)) {
        this.errors.push("Debe indicar la temperatura");
      }
      if (this.carga.oxigeno_centro == "" || isNaN(this.carga.oxigeno_centro)) {
        this.errors.push("Debe indicar el oxigeno");
      }
      if (this.carga.saturacion_centro == "" || isNaN(this.carga.saturacion_centro)) {
        this.errors.push("Debe indicar la saturación");
      }
      if (!temporal) {
        //console.log('parametros', this.parameters)
        // if (!this.carga.guia_despacho) {
        //   this.errors.push("Debe indicar guía de despacho");
        // }

        //validación guía quitada
        /*if (/^[0-9-]+$/.test(this.carga.guia_despacho) == false) {
          this.errors.push("Formato no válido en guía de despacho");
        }*/

        // if (!this.carga.fecha_despacho) {
        //   this.errors.push("Debe indicar fecha de despacho");
        // }
        if (!this.carga.especie) {
          this.errors.push("Debe indicar especie");
        }
        if (this.carga.dias_ayuno == "") {
          this.errors.push("Debe indicar dias de ayuno");
        }

        if (this.termino_cam == null) {
          this.errors.push("Debe indicar la Fecha/Hora CAM");
        }

        if (this.carga.es_nueva) {
          //usando nuevo formato de informacion general
          if (!this.buscar_valor_parametro(1)) {
            this.errors.push("Debe indicar natación");
          }
          if (!this.buscar_valor_parametro(2)) {
            this.errors.push("Debe indicar boqueo en bodegas");
          }
          if (!this.buscar_valor_parametro(3)) {
            this.errors.push("Debe indicar descamación");
          }
          if (!this.buscar_valor_parametro(4)) {
            this.errors.push("Debe indicar esquinados");
          }
          if (!this.buscar_valor_parametro(5)) {
            this.errors.push("Debe indicar ataques de lobo");
          }
          if (!this.buscar_valor_parametro(6)) {
            this.errors.push("Debe indicar heridas");
          }
          if (!this.buscar_valor_parametro(7)) {
            this.errors.push("Debe indicar mortalidad en cerco");
          }
        } else {
          //usando antiguo formato de informacion general
          if (!this.carga.natacion || this.carga.natacion == "") {
            this.errors.push("Debe indicar natación");
          } else {
            if (this.carga.natacion.length > 20) {
              this.errors.push("Natación no debe superar 20 caracteres");
            }
          }


          if (!this.carga.boqueo || this.carga.boqueo == "") {
            this.errors.push("Debe indicar boqueo en bodegas");
          } else {
            if (this.carga.boqueo.length > 20) {
              this.errors.push("Boqueo no debe superar 20 caracteres");
            }
          }


          if (!this.carga.descamacion || this.carga.descamacion == "") {
            this.errors.push("Debe indicar descamación");
          } else {
            if (this.carga.descamacion.length > 20) {
              this.errors.push("Descamacion no debe superar 20 caracteres");
            }
          }

          if (!this.carga.esquinados || this.carga.esquinados == "") {
            this.errors.push("Debe indicar esquinados");
          } else {
            if (this.carga.esquinados.length > 20) {
              this.errors.push("Esquinados no debe superar 20 caracteres");
            }
          }


          if (!this.carga.lobos || this.carga.lobos == "") {
            this.errors.push("Debe indicar ataques de lobo");
          } else {
            if (this.carga.lobos.length > 20) {
              this.errors.push("Ataques de lobo no debe superar 20 caracteres");
            }
          }


          if (!this.carga.heridas || this.carga.heridas == "") {
            this.errors.push("Debe indicar heridas");
          } else {
            if (this.carga.heridas.length > 20) {
              this.errors.push("Heridas no debe superar 20 caracteres");
            }
          }


          if (!this.carga.mortalidad_cerco || this.carga.mortalidad_cerco == "") {
            this.errors.push("Debe indicar mortalidad en cerco");
          } else {
            if (this.carga.mortalidad_cerco.length > 20) {
              this.errors.push("Mortalidad cerco no debe superar 20 caracteres");
            }
          }
        }



        if (this.carga.lances.length == 0) {
          this.errors.push("Debe ingresar al menos un lance");
        }
        if (this.carga.bodegas.length == 0) {
          this.errors.push("Debe ingresar al menos una bodega");
        } else {
          // validar sellos
          this.carga.bodegas.forEach(b => {
            if (b.bodega != undefined) {
              if (b.bodega.bodega != undefined) {
                if (b.bodega.bodega.nombre.includes('Babor') && (!this.carga.sello_babor || this.carga.sello_babor == "")) {
                  this.errors.push("Debe indicar sello babor");
                }
                if (b.bodega.bodega.nombre.includes('Estribor') && (!this.carga.sello_estribor || this.carga.sello_estribor == "")) {
                  this.errors.push("Debe indicar sello estribor");
                }
                if (b.bodega.bodega.nombre.includes('Central') && (!this.carga.sello_central || this.carga.sello_central == "")) {
                  this.errors.push("Debe indicar sello central");
                }
                if (b.bodega.bodega.nombre.includes('Proa') && (!this.carga.sello_babor || this.carga.sello_babor == "")) {
                  this.errors.push("Debe indicar sello proa");
                }
                if (b.bodega.bodega.nombre.includes('Popa') && (!this.carga.sello_central || this.carga.sello_central == "")) {
                  this.errors.push("Debe indicar sello popa");
                }
              } else {
                if (b.bodega.nombre.includes('Babor') && (!this.carga.sello_babor || this.carga.sello_babor == "")) {
                  this.errors.push("Debe indicar sello babor");
                }
                if (b.bodega.nombre.includes('Estribor') && (!this.carga.sello_estribor || this.carga.sello_estribor == "")) {
                  this.errors.push("Debe indicar sello estribor");
                }
                if (b.bodega.nombre.includes('Central') && (!this.carga.sello_central || this.carga.sello_central == "")) {
                  this.errors.push("Debe indicar sello central");
                }
                if (b.bodega.nombre.includes('Proa') && (!this.carga.sello_babor || this.carga.sello_babor == "")) {
                  this.errors.push("Debe indicar sello proa");
                }
                if (b.bodega.nombre.includes('Popa') && (!this.carga.sello_central || this.carga.sello_central == "")) {
                  this.errors.push("Debe indicar sello popa");
                }
              }
            }
          })
        }
        if (this.carga.jefe_centro == "" || this.carga.jefe_centro == null || this.carga.jefe_centro == undefined) {
          this.errors.push("Debe indicar jefe de centro");
        }
        if (this.carga.tecnico_wellboat == "" || this.carga.tecnico_wellboat == null || this.carga.tecnico_wellboat == undefined) {
          this.errors.push("Debe indicar tecnico wellboat");
        }
        if (!this.checkLanceVsBodega(0)) {
          this.errors.push("No es igual n° de peces en lances que numero de peces en bodega");
        }
      }
      if (this.errors.length > 0) {
        //this.validation_message = this.errors.join('.');
        this.validation_message = "Revise los siguientes problemas de validación:";
        this.showSnackBar(true);
        return;
      }
      if (this.carga.lances.length > 0) {
        // se actualiza hora de inicio y termino del parte
        let ul = this.carga.lances.length;
        let l1 = this.carga.lances[0];
        let l2 = this.carga.lances[ul - 1];
        let t = this.temporales[0];
        if (this.carga.lances.length > 0) {
          t.inicio_faena = l1.hora_inicio;
          t.termino_faena = l2.hora_termino;
        }
        // se crea array de lances para guardar
        this.carga.lances.forEach(l => {
          let lance = {
            cargas_id: l.cargas_id,
            numero: l.numero,
            hora_inicio: this.moment(l.hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
            oxigeno: l.oxigeno,
            saturacion: l.saturacion,
            temperatura: l.temperatura,
            peces: l.peces,
            hora_termino: this.moment(l.hora_termino).format("YYYY-MM-DD HH:mm:ss"),
            hora_medicion: this.moment(l.hora_medicion).format("YYYY-MM-DD HH:mm:ss"),
          };
          lances.push(lance);
        });
      }
      this.carga.bodegas.forEach(b => {
        bodegas.push({
          cargas_id: 0,
          bodegas_id: (b.bodega.bodega != undefined) ? b.bodega.bodega.id : (b.bodega != undefined) ? b.bodega.id : 0,
          peces: b.peces,
          peso: b.peso,
          biomasa: b.biomasa,
          densidad: b.densidad,
          lances: b.lances,
          jaula: b.jaula,
          estanques: 0,
        })
      });

      let tecnico_wellboat = this.carga.tecnico_wellboat;
      if (typeof this.carga.tecnico_wellboat === 'object') {
        tecnico_wellboat = this.carga.tecnico_wellboat.nombre;
      }

      let url = `${this.base_url}cargas`;
      let cargaData = {
        main: {
          partes_id: this.carga.parte.id,
          wellboats_id: this.user.wellboat.id,
          guia_despacho: this.carga.guia_despacho,
          fecha_despacho: this.carga.fecha_despacho,
          especies_id: this.carga.especie.id,
          dias_ayuno: this.carga.dias_ayuno,
          natacion: this.carga.natacion,
          boqueo: this.carga.boqueo,
          descamacion: this.carga.descamacion,
          esquinados: this.carga.esquinados,
          lobos: this.carga.lobos,
          heridas: this.carga.heridas,
          mortalidad_cerco: this.carga.mortalidad_cerco,
          sello_babor: this.carga.sello_babor,
          sello_central: this.carga.sello_central,
          sello_estribor: this.carga.sello_estribor,
          sello_yoma1: this.carga.sello_yoma1,
          sello_yoma2: this.carga.sello_yoma2,
          temperatura_centro: this.carga.temperatura_centro,
          oxigeno_centro: this.carga.oxigeno_centro,
          saturacion_centro: this.carga.saturacion_centro,
          observaciones: this.carga.observaciones,
          jefe_centro: this.carga.jefe_centro,
          tecnico_wellboat: tecnico_wellboat,
          encargado_smolt: null,
          temporal: temporal,
          parametros: this.parameters,
          smolt: 0
        },
        bodegas: bodegas,
        parametros: lances,
        tipo_partes_id:this.carga.parte.tipopartes_id,
        termino_cam: this.termino_cam ? this.moment(this.termino_cam).format("YYYY-MM-DD HH:mm:ss") : null
      };
      let response = null;
      let msg = null
      if (temporal == 0) {
        msg = "Ahora debe ir a Parte de viaje, ingresar Fecha/Hora CAM y Guardar Parte de Viaje";
      }
      if (this.carga.id > 0) {
        url = url + "/" + this.carga.id;
        try {
          response = await this.axios.put(url, cargaData, this.headers_json);
          this.loadCargaParte();
          this.showSnackBar(false, msg);
        } catch (error) {
          response = error.response;
          this.errors = response.data.data;
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes problemas de validación:"
          this.showSnackBar(true);
        }
      } else {
        try {
          response = await this.axios.post(url, cargaData, this.headers_json);
          this.loadCargaParte();
          this.showSnackBar(false, msg);
          if (temporal == 0) this.panel = 1;
        } catch (error) {
          response = error.response;
          this.errors = response.data.data;
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes problemas de validación:"
          this.showSnackBar(true);
        }
      }
      if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
        let myWindow = window;
        window.open(this.storage_url + response.data.url_pdf, "_blank");
        myWindow.focus();
      }
    },
    async saveTraslado(temporal = 1) {
      // validation
      this.errors = [];
      if (!this.parte.folio) {
        this.errors.push("Debe elegir un parte de viaje");
      }
      if (this.traslado.parametros.length == 0) {
        this.errors.push("Debe ingresar al menos una medición");
      } else {
        let traslado = this.traslado.parametros[0];
        if (!traslado.hora) {
          this.errors.push("Debe ingresar hora de la medición");
        }
      }
      if (!temporal) {
        if (!this.traslado.tecnico_wellboat) {
          this.errors.push("Debe indicar técnico wellboat");
        }
        if (!this.traslado.supervisor) {
          this.errors.push("Debe indicar supervisor de peces");
        }
        if (!this.traslado.capitan) {
          this.errors.push("Debe indicar capitán wellboat");
        }
        if (!this.traslado.centro_descarga) {
          this.errors.push("Falta centro de descarga");
        }
        if (!this.traslado.hora_inicio) {
          this.errors.push("Falta hora de inicio");
        }
        if (!this.traslado.hora_termino) {
          this.errors.push("Falta hora de termino");
        }
      }
      let parametros = [];
      this.traslado.parametros.forEach(p => {
        p.hora = this.moment(p.hora).format('YYYY-MM-DD HH:mm:ss');
        /*
        if(p.hora < this.traslado.hora_inicio){
          this.errors.push("Hora de medición debe ser mayor que hora de inicio");
        }
        */
        parametros.push(p);
      });

      if (this.errors.length > 0) {
        //this.validation_message = this.errors.join('. ');
        this.validation_message = "Revise los siguientes problemas de validación:";
        this.showSnackBar(true);
        return;
      }

      let tecnico_wellboat = this.traslado.tecnico_wellboat;
      if (typeof this.traslado.tecnico_wellboat === 'object') {
        tecnico_wellboat = this.traslado.tecnico_wellboat.nombre;
      }
      let capitan = "";
      if (typeof this.traslado.capitan == 'object') {
        if (this.traslado.capitan.nombre != undefined) {
          capitan = this.traslado.capitan.nombre;
        }
      } else {
        capitan = this.traslado.capitan;
      }

      let url = `${this.base_url}traslados`;
      let trasladoData = {
        main: {
          partes_id: this.traslado.parte.id,
          wellboats_id: this.user.wellboat.id,
          observaciones: this.traslado.observaciones,
          tecnico_wellboat: tecnico_wellboat,
          supervisor: this.traslado.supervisor,
          capitan: capitan,
          centro_carga: this.traslado.centro_carga,
          centro_descarga: this.traslado.centro_descarga,
          hora_inicio: this.reformatDateTime(this.traslado.hora_inicio),
          hora_termino: this.reformatDateTime(this.traslado.hora_termino),
          temporal: temporal,
          guia_despacho: this.traslado.guia_despacho,
          guia_despacho_2: this.traslado.guia_despacho_2,
          carga_id_1: this.traslado.carga_id_1,
          carga_id_2: this.traslado.carga_id_2,
        },
        parametros: parametros,
      };
      let response = null;
      if (this.traslado.id > 0) {
        url = url + "/" + this.traslado.id;
        try {
          response = await this.axios.put(url, trasladoData, this.headers_json);
          this.loadTrasladoParte();
          this.loadCargaParte();
          this.showSnackBar(false);
        } catch (error) {
          response = error.response;
          this.errors = response.data.data
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes errores de validación:";
          this.showSnackBar(true);
        }
      } else {
        try {
          response = await this.axios.post(url, trasladoData, this.headers_json);
          this.loadTrasladoParte();
          this.loadCargaParte();
          this.showSnackBar(false);
          if (temporal == 0) this.panel = 2;
        } catch (error) {
          response = error.response;
          if (response == undefined) {
            this.showSnackBar(false);
            return;
          }
          this.errors = response.data.data
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes errores de validación:";
          this.showSnackBar(true);
        }
      }
      if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
        window.open(this.storage_url + response.data.url_pdf);
      }
    },
    async saveDescarga(temporal = 1) {
      // validation
      this.errors = [];
      let hora_inicio = "";
      let hora_termino = "";

      let tecnico_wellboat = this.descarga.tecnico_wellboat;
      if (typeof this.descarga.tecnico_wellboat === 'object') {
        tecnico_wellboat = this.descarga.tecnico_wellboat.nombre;
      }
      let capitan = "";
      //console.log(this.descarga.capitan)
      if (typeof this.descarga.capitan == 'object') {
        capitan = this.descarga.capitan.nombre;
      } else {
        capitan = this.descarga.capitan;
      }
      //console.log('capitan : ', capitan)

      if (!this.parte.folio) {
        this.errors.push("Debe elegir un parte de viaje");
      }
      if (!temporal) {
        if (isNaN(this.descarga.saturacion_centro)) {
          this.errors.push("Debe ingresar saturacion de oxigeno");
        }
        if (isNaN(this.descarga.temperatura_centro)) {
          this.errors.push("Debe ingresar temperatura");
        }
        if (isNaN(this.descarga.oxigeno_centro)) {
          this.errors.push("Debe ingresar oxigeno");
        }
        if (!this.descarga.encargado_acopio) {
          this.errors.push("Debe ingresar encargado acopio");
        }
        if (tecnico_wellboat == "") {
          this.errors.push("Debe ingresar tecnico wellboat");
        }
        if (capitan == "") {
          this.errors.push("Debe ingresar capitan");
        }
        if (this.descarga.jaulas.length == 0) {
          this.errors.push("Debe ingresar al menos una descarga");
        } else {
          hora_inicio = this.descarga.jaulas.reduce(function (p, v) {
            return (p.hora_inicio < v.hora_inicio ? p.hora_inicio : v.hora_inicio);
          });
          this.descarga.hora_inicio = hora_inicio;
          hora_termino = this.descarga.jaulas.reduce(function (p, v) {
            return (p.hora_termino < v.hora_termino ? p.hora_termino : v.hora_termino);
          });
          this.descarga.hora_termino = hora_termino;
        }
        if (!this.descarga.recalada) {
          //this.errors.push("Falta hora de recalada");
        }
        if (!this.descarga.atraco) {
          //this.errors.push("Falta hora de atraco");
        }
        if (!this.descarga.hora_inicio) {
          this.errors.push("Falta hora de inicio");
        }
        if (!this.descarga.hora_termino) {
          this.errors.push("Falta hora de termino");
        }
        if (this.descarga.muestreo_repla != undefined && this.descarga.muestreo_repla.length > 30) {
          this.errors.push("Muestreo Repla no debe superar 30 caracteres");
        }
        if (this.descarga.entidad_muestradora != undefined && this.descarga.entidad_muestradora.length > 30) {
          this.errors.push("Entidad muestradora no debe superar 30 caracteres");
        }
        if (this.descarga.mortalidad != undefined && this.descarga.mortalidad.length > 30) {
          this.errors.push("Mortalidad no debe superar 30 caracteres");
        }
      }
      if (this.errors.length > 0) {
        //this.validation_message = this.errors.join(". ");
        this.validation_message = "Revise los siguientes problemas de validación:";
        this.showSnackBar(true);
        return;
      }
      let jaulas = [];
      this.descarga.jaulas.forEach(l => {
        let jaula = {
          descargas_id: l.descargas_id,
          bodegas_id: l.bodegas_id,
          hora_inicio: this.moment(l.hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
          hora_termino: this.moment(l.hora_termino).format("YYYY-MM-DD HH:mm:ss"),
          jaula: l.jaula,
        };
        jaulas.push(jaula);
      });


      let url = `${this.base_url}descargas`;
      let descargaData = {
        main: {
          partes_id: this.descarga.parte.id,
          wellboats_id: this.user.wellboat.id,
          muestreo_repla: this.descarga.muestreo_repla,
          entidad_muestradora: this.descarga.entidad_muestradora,
          mortalidad: this.descarga.mortalidad,
          saturacion_centro: this.descarga.saturacion_centro,
          temperatura_centro: this.descarga.temperatura_centro,
          oxigeno_centro: this.descarga.oxigeno_centro,
          observaciones: this.descarga.observaciones,
          capitan: capitan,
          tecnico: tecnico_wellboat,
          encargado_acopio: this.descarga.encargado_acopio,
          temporal: temporal
        },
        jaulas: jaulas,
      };
      let response = null;
      let msg = null
      if (temporal == 0) {
        msg = "Ahora debe ir a Parte de viaje, ingresar Fecha/Hora CAM y Guardar Parte de Viaje";
      }
      if (this.descarga.id > 0) {
        url = url + "/" + this.descarga.id;
        try {
          response = await this.axios.put(url, descargaData, this.headers_json);
          this.loadDescargaParte();
          this.showSnackBar(false, msg);
        } catch (error) {
          response = error.response;
          this.errors = response.data.data;
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes problemas de validación:"
          this.showSnackBar(true);
        }
      } else {
        try {
          response = await this.axios.post(url, descargaData, this.headers_json);
          this.loadDescargaParte();
          this.showSnackBar(false, msg);
        } catch (error) {
          response = error.response;
          this.errors = response.data.data;
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes problemas de validación:"
          this.showSnackBar(true);
        }
      }
      if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
        window.open(this.storage_url + response.data.url_pdf);
      }
    },
    async saveDescargaPlanta(temporal = 1) {
      // validation
      this.errors = [];
      let hora_inicio = "";
      let hora_termino = "";
      if (!this.parte.folio) {
        this.errors.push("Debe elegir un parte de viaje");
      }
      // horas
      let horas = [];
      this.horas1.forEach(hd => {
        let hora = {
          partes_id: hd.partes_id,
          descarga_plantas_id: hd.descarga_plantas_id,
          hora_inicio: this.moment(hd.hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
          hora_termino: this.moment(hd.hora_termino).format("YYYY-MM-DD HH:mm:ss"),
          motivo: hd.motivo,
        };
        horas.push(hora);
      });
      this.horas2.forEach(hd => {
        let hora = {
          partes_id: hd.partes_id,
          descarga_plantas_id: hd.descarga_plantas_id,
          hora_inicio: this.moment(hd.hora_inicio).format("YYYY-MM-DD HH:mm:ss"),
          hora_termino: this.moment(hd.hora_termino).format("YYYY-MM-DD HH:mm:ss"),
          motivo: hd.motivo,
        };
        horas.push(hora);
      });
      if (!temporal) {
        if (this.descarga_planta.encargado == "" || this.descarga_planta.encargado == null || this.descarga_planta.encargado == undefined) {
          this.errors.push("Debe ingresar encargado");
        }
        if (this.descarga_planta.tecnico_wellboat == "" || this.descarga_planta.tecnico_wellboat == null || this.descarga_planta.tecnico_wellboat == undefined) {
          this.errors.push("Debe ingresar tecnico wellboat");
        }
        if (this.descarga_planta.capitan == undefined || this.descarga_planta.capitan == null || this.descarga_planta.capitan == "") {
          this.errors.push("Debe ingresar capitan");
        }
        if (horas.length == 0) {
          this.errors.push("Debe ingresar al menos una descarga");
        } else {
          hora_inicio = horas.reduce(function (p, v) {
            return (p.hora_inicio < v.hora_inicio ? p.hora_inicio : v.hora_inicio);
          });
          this.descarga_planta.hora_inicio = hora_inicio;
          hora_termino = horas.reduce(function (p, v) {
            return (p.hora_termino < v.hora_termino ? p.hora_termino : v.hora_termino);
          });
          this.descarga_planta.hora_termino = hora_termino;
        }
        if (this.descarga_planta.muestreo != undefined && this.descarga_planta.muestreo.length > 30) {
          this.errors.push("Muestreo Repla no debe superar 30 caracteres");
        }
      }
      if (this.errors.length > 0) {
        //this.validation_message = this.errors.join(". ");
        this.validation_message = "Revise los siguientes problemas de validación:"
        this.showSnackBar(true);
        return;
      }
      // tecnico y capitán
      let tecnico_wellboat = this.descarga_planta.tecnico_wellboat;
      if (typeof this.descarga_planta.tecnico_wellboat === 'object') {
        tecnico_wellboat = this.descarga_planta.tecnico_wellboat.nombre;
      }
      let capitan = "";
      if (typeof this.descarga_planta.capitan == 'object') {
        if (this.descarga_planta.capitan.nombre != undefined) {
          capitan = this.descarga_planta.capitan.nombre;
        }
      } else {
        capitan = this.descarga_planta.capitan;
      }
      let url = `${this.base_url}descarga_plantas`;
      let data = {
        main: {
          wellboats_id: this.user.wellboat.id,
          partes_id: this.descarga_planta.parte.id,
          capitan: capitan,
          tecnico_wellboat: tecnico_wellboat,
          encargado: this.descarga_planta.encargado,
          guia: this.descarga_planta.guia,
          fecha: this.descarga_planta.fecha,
          muestreo: this.descarga_planta.muestreo,
          observaciones: this.descarga_planta.observaciones,
          temporal: temporal
        },
        horas: horas,
      };
      let response = null;
      let msg = null
      if (temporal == 0) {
        msg = "Ahora debe ir a Parte de viaje, ingresar Fecha/Hora CAM y Guardar Parte de Viaje";
      }
      if (this.descarga_planta.id > 0) {
        url = url + "/" + this.descarga_planta.id;
        try {
          response = await this.axios.put(url, data, this.headers_json);
          this.showSnackBar(false, msg);
          this.loadDescargaParte();
        } catch (error) {
          response = error.response;
          this.errors = response.data.data;
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes problemas de validación:"
          this.showSnackBar(true);
        }
      } else {
        try {
          response = await this.axios.post(url, data, this.headers_json);
          this.loadDescargaParte();
          this.showSnackBar(false, msg);
        } catch (error) {
          response = error.response;
          this.errors = response.data.data;
          /*let msg = response.data.data;
          this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;*/
          this.validation_message = "Revise los siguientes problemas de validación:"
          this.showSnackBar(true);
          //save_error = true;
        }
      }
      if (response.data.url_pdf != undefined && response.data.url_pdf != "") {
        window.open(this.storage_url + response.data.url_pdf);
      }
    },
    async saveFinal() {
      if (this.files.length < 1) {
        this.errors.push("Debe subir al menos 1 documento");
      }
      if (this.errors.length > 0) {
        this.validation_message = this.errors.join();
        this.showSnackBar(true);
        return;
      }
      let formData = new FormData();
      let nf = this.files.length;
      let i = 0;
      this.files.forEach(f => {
        formData.append("doc" + i, f);
        i++;
      })
      formData.append('partes_id', this.carga.parte.id);
      formData.append('num_files', nf);

      let response = null;
      let url = `${this.base_url}cargas/bitacoras/finalizar`;
      //this.headers.headers['Content-Type'] = "multipart/form-data";
      //console.log(this.headers);
      //if(this.headers) return;
      try {
        response = await this.axios.post(url, formData, this.headers_file);
        this.headers.headers['Content-Type'] = "application/json;charset=utf-8";
        this.$alert("¡Ejecutado exitósamente! debe ir al parte de viaje, indicar fecha/hora CAM y guardar el parte de viaje");
        //this.showSnackBar(false);
      } catch (error) {
        response = error.response;
        let msg = response.data.data;
        this.validation_message = response.status + ': ' + response.data.message + '. ' + msg;
        this.showSnackBar(true);
      }
    },
    addLance() {
      if (this.carga.lances == undefined) this.carga.lances = [];
      let n = this.carga.lances.length;
      console.log(n);
      let lance = {
        'id': 0,
        'menu1': false,
        'menu2': false,
        'menu3': false,
        'cargas_id': 0,
        'numero': n + 1,
        'hora_inicio': null,
        'hora_medicion': null,
        'oxigeno': '0',
        'saturacion': '0',
        'temperatura': '0',
        'peces': '0',
        'hora_termino': null,
        'carga_efectiva': '00:00'
      };
      this.$set(this.carga.lances, n, lance);
    },
    addMedicionTraslado() {
      let n = 0;
      if (this.traslado.parametros != undefined) {
        n = this.traslado.parametros.length;
      }
      let parametro = {
        'id': 0,
        'menu1': false,
        'traslados_id': 0,
        'numero': n + 1,
      };
      this.bodegas.forEach(b => {
        // parametro['b'+b.bodega.id] = {oxigeno: 0, ph: 0, uv: 0, temperatura: 0}
        parametro['b' + b.bodega.id] = { oxigeno: null, ph: null, uv: null, temperatura: null }
      });
      if (n == 0) {
        this.traslado.parametros = [];
        this.traslado.parametros.push(parametro)
      } else {
        this.$set(this.traslado.parametros, n, parametro);
      }
    },
    addHoraDescarga(id) {
      let parte = this.parte1.id;
      if (id == 2) {
        parte = this.parte2.id;
      }
      let n = (id == 1) ? this.horas1.length : this.horas2.length;
      let hora = {
        'numero': n + 1,
        'partes_id': parte,
        'hora_inicio': '00:00',
        'hora_medicion': '00:00',
        'motivo': ''
      };
      if (id == 1) {
        this.$set(this.horas1, n, hora);
      }
      if (id == 2) {
        this.$set(this.horas2, n, hora);
      }
    },
    addCargaBodega() {
      this.errors = [];
      if (this.bodega.bodega == undefined || this.bodega.bodega.bodega == undefined || this.bodega.bodega.bodega.nombre == "") {
        this.errors.push("Debe elegir una bodega");
      }
      if (!this.bodega.peces > 0) {
        this.errors.push("Debe indicar peces");
      }
      if (!this.bodega.peso > 0) {
        this.errors.push("Debe indicar peso");
      }
      if (!this.bodega.biomasa > 0) {
        this.errors.push("Debe indicar biomasa");
      }
      if (!this.bodega.densidad > 0) {
        this.errors.push("Debe indicar densidad");
      }
      if (this.bodega.lances == "") {
        this.errors.push("Debe indicar lance(s)");
      }
      if (this.bodega.jaula == "") {
        this.errors.push("Debe indicar jaula(s)");
      }
      if (this.errors.length > 0) {
        this.validation_message = this.errors.join();
        this.showSnackBar(true);
        return;
      }
      let n = this.carga.bodegas.length;
      let b = {
        'id': 0,
        'numero': n + 1,
        'bodega': this.bodega.bodega,
        'peces': this.bodega.peces,
        'peso': this.bodega.peso,
        'biomasa': Math.round(this.bodega.biomasa, 0),
        'densidad': this.bodega.densidad,
        'lances': this.bodega.lances,
        'jaula': this.bodega.jaula,
      };
      this.carga.bodegas.push(b);
      this.dialog = false;
      this.bodega = {
        bodega: {},
        peces: 0,
        peso: 0,
        biomasa: 0,
        densidad: 0,
        lances: '',
        jaula: ''
      };
    },
    addDescarga() {
      let n = this.descarga.jaulas.length;
      let descarga = {
        'id': 0,
        'menu1': false,
        'menu2': false,
        'descargas_id': 0,
        'bodega': this.bodegas[0],
        'numero': n + 1,
        'hora_inicio': '00:00',
        'hora_termino': '00:00',
        jaula: ''
      };
      this.$set(this.descarga.jaulas, n, descarga);
    },
    deleteBodega(index) {
      //const index = this.carga.bodegas.indexOf(item);
      this.carga.bodegas.splice(index, 1);
      //enumerar cargas bodegas
      let num = 1;
      this.carga.bodegas.forEach((b,i)=>{
        this.$set(this.carga.bodegas[i],'numero',num);
        num++;
      })
    },
    deleteLance(index) {
      //const index = this.carga.lances.indexOf(item);
      this.carga.lances.splice(index, 1);
      //enumerar lances
      let num = 1;
      this.carga.lances.forEach((b,i)=>{
        this.$set(this.carga.lances[i],'numero',num);
        num++;
      })
    },
    deleteDescargaJaula(index) {
      //const index = this.descarga.jaulas.indexOf(item);
      this.descarga.jaulas.splice(index, 1);
    },
    deleteParametroTraslado(index) {
      //const index = this.traslado.parametros.indexOf(item);
      this.traslado.parametros.splice(index, 1);
      //enumerar parametros traslado
      let num = 1;
      this.traslado.parametros.forEach((p,i)=>{
        this.$set(this.traslado.parametros[i],'numero',num);
        num++;
      })
    },
    deleteHora(item, guia) {
      if (guia == 1) {
        const index = this.horas1.indexOf(item);
        this.horas1.splice(index, 1);
      }
      if (guia == 2) {
        const index = this.horas2.indexOf(item);
        this.horas2.splice(index, 1);
      }
    },
    showSnackBar(error, mensaje = 'Ejecutado exitosamente!') {
      if (mensaje == null) mensaje = 'Ejecutado exitosamente!';
      if (error) {
        this.color = 'red';
      } else {
        this.color = 'green';
        this.validation_message = mensaje;
        this.cleanForm();
      }
      this.snackbar = true;
    },
    cleanForm() {
      return;
    },
    ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}-${month}-${year}`
    },
    reformatDateTime(datetime) {
      if (!datetime) return null;
      const [date, time] = datetime.split(' ');
      const [day, month, year] = date.split('-');
      return `${year}-${month}-${day} ${time}`;
    },
    calcularBiomasa() {
      let peso = this.bodega.peso.toString();
      peso = peso.replace(',', '.');
      this.bodega.peso = peso;
      this.bodega.biomasa = (parseFloat(this.bodega.peces) * parseFloat(peso)).toFixed(0);
      this.bodega.densidad = (this.bodega.biomasa / this.bodega.bodega.m3).toFixed(2);
    },
    checkGuia() {
      if (/^[0-9-]+$/.test(this.traslado.guia_despacho) == false || /^[0-9-]+$/.test(this.traslado.guia_despacho_2)) {
        this.validation_message = "Formato no válido en guía de despacho";
        this.showSnackBar(true);
      }
    },
    cargaEfectiva(lance) {
      if (lance.hora_inicio && lance.hora_termino) {
        if (lance.hora_inicio > lance.hora_termino) {
          this.errors = ["Hora término es menor que la de inicio"];
          //this.validation_message = "Hora término es menor que la de inicio";
          this.validation_message = "Revise los siguientes problemas de validación:";
          this.showSnackBar(true);
          //lance.hora_termino = null;
          return;
        }
        let h2 = this.moment(lance.hora_termino);
        let h1 = this.moment(lance.hora_inicio);
        let seconds = h2.diff(h1, 'seconds');
        let millis = this.moment.duration({ "seconds": seconds }).asMilliseconds();
        let tiempo = this.moment.utc(millis).format("HH:mm");
        const index = this.carga.lances.indexOf(lance);
        lance.carga_efectiva = tiempo;
        this.$set(this.carga.lances, index, lance);
      }
    },
    /*checkHoras(lance) {
      if (lance.hora_inicio || lance.hora_termino) return;
      if (lance.hora_inicio > lance.hora_medicion) {
        this.errors = ["Hora medición es menor que la de inicio"];
        //this.validation_message = "Hora medición es menor que la de inicio";
        this.validation_message = "Revise los siguientes problemas de validación:";
        this.showSnackBar(true);
        //lance.hora_medicion = null;
      }
    },*/
    recalcular(item) {
      let index = this.carga.bodegas.indexOf(item);
      let bodega = item;
      bodega.biomasa = (bodega.peces * bodega.peso).toFixed(2);
      let m3 = this.bodegas.find(b => b.bodega.id == item.bodega.id).m3
      bodega.densidad = (bodega.biomasa / m3).toFixed(2);
      this.$set(this.carga.bodegas, index, bodega);
    },
    checkLanceVsBodega(peces) {
      // validar total lances vs total bodegas
      let sum_bodegas = parseInt(peces);
      let sum_lances = 0;
      this.carga.lances.forEach(l => {
        sum_lances += parseInt(l.peces);
      });
      this.carga.bodegas.forEach(b => {
        sum_bodegas += parseInt(b.peces);
      });
      return sum_lances == sum_bodegas;
    }
  },
  computed: {
    ...mapState(['storage_url', 'loading', 'base_url', 'headers', 'headers_json','headers_file','user']),
    fechaGuiaFormatted() {
      return this.formatDate(this.carga.fecha_despacho);
    },
    fechaGuiaPlanta() {
      return this.formatDate(this.descarga_planta.fecha);
    },
    totalPeces() {
      if (this.carga.bodegas == undefined) return 0;
      let total = 0;
      this.carga.bodegas.forEach(b => {
        total += parseFloat(b.peces);
      });
      return total;
    },
    totalPeso() {
      if (this.totalBiomasa != 0 && this.totalPeces != 0) {
        return (this.totalBiomasa / this.totalPeces).toFixed(3);
      }
      return 0;
    },
    totalBiomasa() {
      if (this.carga.bodegas == undefined) return 0;
      let total = 0;
      this.carga.bodegas.forEach(b => {
        total += parseFloat(b.biomasa);
      });
      return total;
    },
    totalDensidad() {
      if (this.carga.bodegas == undefined) return 0;
      let total = 0;
      this.carga.bodegas.forEach(b => {
        total += parseFloat(b.biomasa);
      });
      let m3_total = this.user.wellboat.m3;
      return (total / m3_total).toFixed(2);
    },
    horaInicio() {
      // el siguiente parte de viaje, interno o de cosecha
      return this.moment(this.carga.parte.zarpe).format('DD-MM-YYYY HH:mm')
    },
    horaTermino() {
      return this.moment(this.carga.parte.recalada).format('DD-MM-YYYY HH:mm')
    },
    centroCarga() {
      if (this.carga.parte.sentidos_id == 1) {
        if (this.carga.parte.origen != undefined) {
          return this.carga.parte.origen.nombre;
        }
      }
      if (this.carga.parte.sentidos_id == 2) {
        if (this.carga.parte.destino != undefined) {
          return this.carga.parte.destino.nombre;
        }
      }
      return "";
    },
    centroDescarga() {
      if (this.carga.parte.sentidos_id == 1) {
        if (this.carga.parte.destino != undefined) {
          return this.carga.parte.destino.nombre;
        }
      }
      if (this.carga.parte.sentidos_id == 2) {
        if (this.carga.parte.origen != undefined) {
          return this.carga.parte.origen.nombre;
        }
      }
      return "";
    },
    finalizar() {
      // si es la primera carga de un parte a duplicar
      if (this.parte.duplicar == 1 && this.carga.temporal == 0) {
        return false;
      }
      // si es la carga de un parte de baño
      if (this.parte.tipopartes_id == 5 && this.carga.temporal == 0) {
        return false;
      }
      // si es descarga normal
      if (this.carga.temporal == 0 && this.traslado.temporal == 0 && this.descarga.temporal == 0) {
        return false;
      }
      // si es descargar directa a planta
      if (this.carga.temporal == 0 && this.traslado.temporal == 0 && this.descarga_planta.temporal == 0) {
        return false;
      }
      return true;
    },
    showCarga() {
      return Object.keys(this.parte).length === 0
    }
  },
  watch: {
    totalDensidad: function (act, prev) {
      if (act != prev && act > this.user.wellboat.toneladas) {
        this.validation_message = `Se sobrepasa densidad wellboat (${this.user.wellboat.toneladas})`;
        this.showSnackBar(true);
      }
    }
  },
  components: {
    TicketBitacora
  }
}
</script>
